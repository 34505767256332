@import '../shared.scss';

.horizontalList {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	display: flex;
	overflow-x: auto;
	flex-direction: row;
	height: 255px;
	margin-bottom: 80px;
	padding: 5px 5px 20px 5px;
}

.horizontalList::after { content: ''; min-width: 5px; }

.horizontalList .productCard:first-child {
	margin-left: 0px;
}
.horizontalList .brandCard:first-child {
	margin-left: 0px;
}

.listTitle {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 20px;
	font-size: 24px;
	color: $primary-color;
}

.desc {
	margin-bottom: 20px;
	margin-top: -15px;
}

.horizontalList::-webkit-scrollbar {
	-webkit-appearance: none;
	height: 7px;
}
  
.horizontalList::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: $primary-color;
}

.horizontalList::-webkit-scrollbar-track {
	background-color: lightgray;
	border-radius: 8px;
}

.brandCard {
	display: block;
	min-width: 180px;
	width: 180px;
	text-align: center;
	margin-right: 0px;
	border-radius: 5px;
	padding: 10px 10px;
	box-shadow: 2px 2px 5px grey;
	margin-left: 10px;
}

.brandCard:hover {
	background-color: $primary-hover-color;
}

.brandCard h1 {
	font-size: 16px;
	margin: 0px;
	margin-top: 20px;
	height: 38px;
	overflow: hidden;
}

.brandLogo {
	display: block;
	width: 100px;
	height: 100px;
	background-color: #ffffff;
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	box-shadow: 2px 2px 5px grey;
}

.showMoreButton {
	display: inline-block;
	float: right;
	text-decoration: none;
	font-size: 16px;
	color: $primary-color;
	width: 90px;
	text-align: right;
}

.productCard {
	display: block;
	min-width: 180px;
	width: 180px;
	text-align: center;
	margin-right: 0px;
	border-radius: 5px;
	padding: 10px 10px;
	box-shadow: 2px 2px 5px grey;
	margin-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.productCard:hover {
	background-color: $primary-hover-color;
}

.productCard h1 {
	font-size: 16px;
	margin: 0px;
	margin-top: 10px;
	height: 38px;
	overflow: hidden;
}

.productImage {
	width: 100%;
	height: 70%;
	object-fit: cover;
	border-radius: 5px;
	overflow: hidden;
}


@media only screen and (max-width: 600px) {
	.horizontalList {
		width: 100%;
		margin-bottom: 40px;
	}
}