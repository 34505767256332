@import '../../shared.scss';

.brandContainer {
	@extend %content;
}

.brandInfoContainer {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	border-radius: 5px;
	padding: 40px;
	padding-bottom: 0px;
	text-align: center;
	width: 800px;
}

.backButton {
	height: 30px;
	width: 100px;
	font-size: 14px;
	border: none;
	background-color: white;
	border-radius: 5px;
}

.backButton:hover {
	background-color: #384457;
	color: white;
}

.brandDescription {
	text-align: justify;
}

.brandTitle {
	font-size: 40px;
	color: #384457;
	font-weight: bold;
}

.brandLogo {
	width: 150px;
	height: 150px;
	background-color: #ffffff;
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	box-shadow: 2px 2px 5px grey;
}

.brandRequestContainer {
	font-size: 24px;
	color: #384457;
	font-weight: bold;
	width: 100%;
	height: 40px;
	line-height: 40px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.requestButton {
	font-size: 25px;
	margin-top: 20px;
	color: #384457;
	border-style: solid;
	border-radius: 5px;
	border-width: 1px;
	border-color: #384457;
	text-decoration: none;
	padding: 10px 20px;
	display: inline-flex; 
	flex-direction: column; 
	justify-content: center; 
	text-align: center;
}

.requestButton:hover {
	background-color: #384457;
	color: white;
}

.productsContainer {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 30px;
	text-align: center;
}

.dividerLine {
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	background-color: gray;
	height: 1px;
	margin-bottom: 40px;
}

.productsGrid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-rows: 1fr;
	width: 100%;
	gap: 40px;
}

.productImageContainer {
	border: 1px solid lightgray;
	overflow: hidden;
	border-radius: 5px;
	padding: 20px 20px;
}

.productImage {
	height: 100%;
	width: 100%;
	max-height: 200px;
	object-fit: cover;
	border-radius: 5px;
}

.samplingTitle {
	margin-top: 40px;
	margin-bottom: 0px;
}

.promoCode {
	font-size: 16px;
	color: #384457;
	font-weight: bold;
	margin: 40px 0px -10px 0px;
}

@media only screen and (max-width: 620px) {
	.brandContainer {
		width: 100%;
		margin-top: 0px;
	}
	.brandInfoContainer {
		width: 100%;
		padding: 0px 00px;
	}
	.brandLogo {
		top: 0px;
	}
	.brandTitle {
		font-size: 24px;
	}

	.productsContainer {
		margin-left: 0px;
		margin-right: 0px;
	}

	.brandDescription {
		font-size: 16px;
	}
	
	.requestButton {
		font-size: 20px;
	}

	.productsGrid {
		gap: 10px;
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: 200px;
	}

	.productImage {
		max-height: 100px;
	}
	.productImageContainer {
		padding: 10px;
	}

	.productImageContainer h3 {
		font-size: 12px;
	}


}