$primary-color: #384457;
$primary-hover-color: rgba(56,68,87,0.2);

$screen-side-margins: 70px;
$main-app-width: 1180px;

%content {
	max-width: $main-app-width;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 80px;
	padding-left: 20px;
	padding-right: 20px;
	@media only screen and (max-width: 620px) {
		width: 100%;
		margin-top: 0px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 40px;
	}
}

%helperText {
	font-size: 14px;
	color: gray;
	font-weight: 500;
}

.inputSection {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.inputLabel {
	font-size: 16px;
	font-weight: bold;
	color: $primary-color;
}

.inputTextField {
	width: 300px;
	margin-top: 10px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid $primary-color;
	font-size: 16px;
	padding-left: 10px;
}

.submitButton {
	margin-top: 20px;
	color: white;
	background-color: $primary-color;
	border-radius: 5px;
	width: 200px;
	height: 40px;
	border: none;
	font-size: 16px;
	font-weight: bold;
}