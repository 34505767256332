@import '../../shared.scss';


.spotlightContainer {
	@extend %content;
	margin-bottom: 20px;
	@media only screen and (max-width: 620px) {
		margin-bottom: 0px;
	}
}



.title {
	font-size: 30px;
	color: $primary-color;
}

.subscribeButton {
	display: inline-block;
	padding: 10px 20px;
	border: none;
	background-color: $primary-color;
	color: white;
	border-radius: 5px;
	font-size: 16px;
	margin-top: -20px;
	margin-bottom: 40px;
	font-weight: 600;
	cursor: pointer;
}

.subscribeButton2 {
	display: inline-block;
	padding: 10px 20px;
	border: none;
	background-color: $primary-color;
	color: white;
	border-radius: 5px;
	font-size: 16px;
	margin-bottom: 40px;
	font-weight: 600;
	cursor: pointer;
}

.promosContainer {
	@extend %content;
	display: block;
	margin-bottom: 20px;
	@media only screen and (max-width: 620px) {
		margin-bottom: 0px;
	}
}

.promoContainer {
	display: flex;
	display: 100%;
	padding: 20px;
	border-bottom: 1px solid #384457;
}

.promoContainer:last-child {
	border-bottom: none;
}

.promoTextContainer {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.promoText {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	margin-left: 20px;
}

.signUp {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	height: 30px;
	width: 100px;
	font-size: 14px;
	border: $primary-color solid 1px;
	background-color: white;
	border-radius: 5px;
	color: $primary-color;
}

.signUp:hover {
	background-color: #384457;
	color: white;
}

.brandLogo {
	display: inline-block;
	width: 100px;
	height: 100px;
	background-color: #ffffff;
	object-fit: contain;
	border-radius: 50%;
	box-shadow: 2px 2px 5px grey;
	margin-bottom: 20px;
}

@media only screen and (max-width: 620px) {
	.signUp {
		position: relative;
		top: auto;
		transform: translateY(0%);
		right: 0;
		margin-left: 20px;
	}
	.promoTextContainer {
		flex-direction: column;
	}
}