@import '../shared.scss';

.searchFilter {
	width: 100%;
}

.categoryCell {
	padding: 10px 0px;
	border-top: 1px solid gray;
	cursor: pointer;
	width: 100%;
}

.categoryCellSelected {
	background: $primary-hover-color;
}

.categoryTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.categoryTitle h3 {
	margin: 0px;
	margin-left: 20px;
	font-size: 14px;
}

.categoriesTable {
	border-collapse: collapse;
	user-select: none;
	width: 100%;
}

.categoriesTable:last-child {
	border-bottom: 1px solid gray;
}

.subCategoriesTable {
	width: 100%;
	margin-top: 10px;
}

.subCategoryCell {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	font-size: 14px;
}

.subCategoryCell::selection {
	background: none;
}

.subCategoryCell:hover {
	background-color: $primary-hover-color;
}

.checkmark {
	height: 10px;
	width: 15px;
	border-left: 2.0px solid black;
	border-bottom: 2.0px solid black;
	transform: scale(-1) rotate(135deg);
}

.chevronDown {
	display: inline-block;
	height: 13px;
	min-width: 13px;
	border-left: 1.0px solid black;
	border-bottom: 1.0px solid black;
	transform: scale(-1) rotate(135deg);
	margin-left: 10px;
	margin-right: 20px;
}

.chevronUp {
	display: inline-block;
	height: 13px;
	min-width: 13px;
	border-left: 1.0px solid black;
	border-bottom: 1.0px solid black;
	transform: scale(-1) rotate(-45deg);
	margin-left: 10px;
	margin-top: 6px;
	margin-right: 20px;
}

.selectButton {
	display: inline;
	text-decoration: none;
	border: none;
	background-color: transparent;
	font-size: 12px;
	font-weight: 600;
	min-width: 70px;
	height: 20px;
	padding: 0px;
	float: right;
}

.titleControls {
	display: flex;
	flex-direction: row;
}