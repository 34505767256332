@import '../shared.scss';

.footContainer {
	width: 100%;
	padding: 0px $screen-side-margins;
	background-color: #384457;
}

.footer {
	margin-left: auto;
	margin-right: auto;
	max-width: $main-app-width;

	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px 20px;
}

.link {
	color: white;
	text-decoration: none;
}

.link:hover {
	color: gray;
}

.legalBS {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.smContainer {
	margin-top: 20px;
	margin-bottom: 20px;
	display: flex;
	width: 60px;
	justify-content: space-between;
}

.sm {
	height: 25px;
	width: 25px;
	margin-right: 5px;
}

@media only screen and (max-width: 600px) {
	.footContainer {
		padding: 0px;
	}

	.footer {
		padding: 20px;
	}
}