@import '../../shared.scss';

.productContainer {
	@extend %content;
	margin-top: 100px;
	display: flex;
	flex-direction: row;
	text-align: left;
	position: relative;
}

.productDetailsContainer {
	padding-left: 50px;
	width: 50%;
}

.productImageContainer {
	width: 50%;
	height: 500px;
}

.productImage {
	width: 100%;
	object-fit: contain;
	height: 100%;
	overflow: hidden;
}

.productName {
	font-size: 36px;
	color: #384457;
	font-weight: bold;
	margin: 5px 0px 0px 0px;
}

.productDescriptor {
	font-size: 24px;
	color: #384457;
	font-weight: medium;
	margin: 0px;
}

.productDescription {
	font-size: 20px;
	color: #384457;
	font-weight: medium;
	margin: 40px 0px 0px 0px;
}

.backButton {
	height: 30px;
	width: 100px;
	font-size: 14px;
	border: none;
	background-color: white;
	border-radius: 5px;
	margin-top: -40px;
}

.backButton:hover {
	background-color: #384457;
	color: white;
}

.brandLogo {
	width: 100px;
	height: 100px;
	background-color: #ffffff;
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	box-shadow: 2px 2px 5px grey;
}

.requestButton {
	font-size: 26px;
	margin-top: 60px;
	color: #384457;
	border-style: solid;
	border-radius: 5px;
	border-width: 1px;
	border-color: #384457;
	text-decoration: none;
	padding: 10px 20px;
	display: inline-flex; 
	flex-direction: column; 
	justify-content: center; 
	text-align: center;
}

.requestButton:hover {
	background-color: #384457;
	color: white;
}

.promoCode {
	font-size: 16px;
	color: #384457;
	font-weight: bold;
	margin: 40px 0px -50px 0px;
}

@media only screen and (max-width: 620px) {
	.backButton {
		margin-top: 0px;
		margin-bottom: 20px;
	}
	.productContainer {
		width: 100%;
		margin-top: 0px;
		flex-direction: column;
		padding: 0px;
	}
	.productDetailsContainer {
		width: 100%;
		padding: 20px;
		text-align: center;
	}
	
	.productImageContainer {
		width: 100%;
		height: 250px;
	}

	.productImage {
		object-fit: cover;
	}

	.requestButton {
		display: block;
		font-size: 20px;
	}

	.productName {
		font-size: 24px;
	}

	.productDescriptor {
		font-size: 20px;
	}
	
	.productDescription {
		font-size: 20px;
		text-align: left;
	}
}