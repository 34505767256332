@import '../shared.scss';

.headContainer {
	width: 100%;
	padding: 0px $screen-side-margins;
}

.header {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	max-width: $main-app-width;
	padding: 0px 20px;
	height: 150px;
	align-items: center;
	flex-direction: row;
}

.headerLogo {
	width: 150px;
}

.navBar {
	display: flex;
	flex-direction: row;
	font-size: 24px;
	font-weight: 700;
	color: black;
}

.navBar ul {
	display: flex;
}

.navBar li {
	float: left;
	list-style-type: none;
	padding: 16px;
}

.navBar a {
	color: inherit;
	text-decoration: none;
}

.navBar a:hover {
	opacity: 0.5;
}

.selectedLink {
	border-bottom: black solid 2px;
	padding-bottom: 8px;
}

.hamburgerMenu {
	display: none !important; 
}

@media only screen and (max-width: 900px) {
	.header {
		padding: 0px 40px;
	}

	.navBar {
		font-size: 20px;
	}

	.navBar li {
		padding: 8px;
	}

	.navBar ul {
		padding-left: 10px;
	}

	.headerLogo {
		width: 100px;
	}
}

@media only screen and (max-width: 600px) {

	.headContainer {
		width: 100%;
		padding: 0px;
	}

	.header {
		height: 80px;
	}

	.hamburgerMenu {
		position: absolute;
		right: 20px;
		display: block !important;
		z-index: 20;
	}

	.navBar {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100vh;
		width: 100vw;
		display: none;
		color: white;
		background-color: rgba($color: #000000, $alpha: 1.0);
		font-size: 30px;
		overflow: hidden;
		z-index: 1;
	}

	.navBar ul {
		flex-direction: column;
	}

	.selectedLink {
		border-bottom: white solid 2px;
	}

	.navBarActive {
		display: block;
	}
}