@import '../../shared.scss';

.crowdstockingContainer {
	min-height: 100%;
	background-color: whitesmoke;
	width: 100%;
	padding: 20px;
}

.content {
	@extend %content;
}

.sectionTitle {
	color: $primary-color;
	font-size: 26px;
	margin-bottom: 10px;
}

h2 {
	color: $primary-color;
	font-size: 20px;
}

p {
	font-size: 16px;
	margin-bottom: 40px;
}

.crowdstockImg {
	max-width: 100%;
}