@import '../../shared.scss';

.searchContainer {
	@extend %content;
	margin-bottom: 20px;
}

.bannerImage {
	margin-top: 20px;
	width: 100%;
	overflow: hidden;
}

.pageTitle {
	text-align: center;
	color: $primary-color;
	margin-bottom: 60px;
}

.searchFieldContainer {
	margin-left: auto;
	margin-right: auto;
	width: 50%;
	height: 44px;
	border: 1px solid #384457;
	border-radius: 25px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 20px;
}

.searchField {
	width: 100%;
	font-size: 24px;
	border: none;
	outline: none;
	background-color: transparent;
}

.searchIcon {
	width: 26px;
	height: 24px;
}

.resultsTable {
	width: 100%;
}

.resultsTable th {
	text-align: left;
	font-size: 24px;
}
.resultsTable td {
	text-align: left;
	padding-left: 30px;
	font-size: 16px;
	border-collapse: collapse;
}

.resultsTable tr:hover:not(:first-child) {
	background-color: #3844575b;
	cursor: pointer;
}

.brandLogo {
	width: 65px;
	height: 65px;
	background-color: #ffffff;
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	box-shadow: 2px 2px 5px grey;
}

.samplingContainer {
	width: 100%;
	margin-top: 100px;
}

.brandGrid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
	gap: 10px;
	text-align: center;
	margin-top: 20px;
}

.brandContainer {
	width: 100%;
	padding: 5px;
	border-radius: 5px;
}

.brandName {
	font-size: 24px;
	margin: 0px;
	margin-top: 20px;
}

.brandContainer:hover {
	background-color: #3844575b;
	cursor: pointer;
}

.requestSection{
	font-size: 20px;
}

.requestButton {
	display: inline-block;
	padding: 10px 20px;
	border: none;
	background-color: $primary-color;
	color: white;
	border-radius: 5px;
	font-size: 16px;
	margin-bottom: 40px;
	font-weight: 600;
	margin-top: -20px;
	cursor: pointer;
}

@media only screen and (max-width: 620px) {
	.searchContainer {
		width: 100%;
		margin-top: 0px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 0px;
	}

	.bannerImage {
		margin-top: 0px;
		width: 100%;
		overflow: hidden;
		height: 100px;
		object-fit: cover;
	}

	.searchField {
		font-size: 24px;
	}

	.brandGrid {
		grid-template-columns: 1fr 1fr;
	}
}