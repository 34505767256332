.modalContainer {
	position: absolute;
	border-radius: 5px;
	width: 600px;
	max-height: 800px;
	padding: 20px 20px 40px 20px;
	background-color: #e9e9e9;
	outline: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	color: #384457;
	font-weight: bold;
	box-shadow: 2px 2px 3px 0px rgba(79, 79, 79, 0.2);
}

.modalContainer select {
	outline:none;
	margin-left: 10px;
	font-size: 16px;
}

.modalButton {
	background: #7593C3;
	border: 0px;
	color: #FFFFFF;
	border-radius: 5px;
	padding: 0.4em 2em;
	font-size: 16px;
	margin: 0px;
	margin-top: 50px;
	cursor: pointer;
}

.modalTitle {
	font-size: 26px;
	color: #384457;
	font-weight: bold;
}