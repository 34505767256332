@import '../../shared.scss';

.brandsContainer{
	@extend %content;
	width: 100%;
	display: flex;
}

.contentList {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));;
	gap: 20px;
	@media only screen and (max-width: 800px) { 
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));;
	}
}

.searchFilterContainer {
	display: inline-block;
	width: 250px;
	min-width: 250px;
	margin-right: 40px;
}

.scrollContainer {
	width: 100%;
}

.brandCard {
	display: block;
	width: 100%;
	height: 200px;
	text-align: center;
	padding: 10px 20px;
	border-radius: 5px;
	box-shadow: 2px 2px 5px grey;
}

.brandCard:hover {
	background-color: $primary-hover-color;
}

.noProductButton {
	display: inline-block;
	padding: 10px 20px;
	border: none;
	background-color: $primary-color;
	color: white;
	border-radius: 5px;
	font-size: 16px;
	margin-bottom: 40px;
	font-weight: 600;
	margin-top: 40px;
	cursor: pointer;
}

.brandLogo {
	display: block;
	width: 100px;
	height: 100px;
	background-color: #ffffff;
	object-fit: contain;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	box-shadow: 2px 2px 5px grey;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	margin-bottom: 40px;
}

.titleContainer h1 {
	font-size: 24px;
}

.sortByContainer {
	font-weight: 600;
	color: $primary-color;
}

.brandTitle {
	font-size: 16px;
	font-weight: 600;
	color: $primary-color;
	margin-top: 20px;
}

.searchFieldContainer {
	width: 70%;
	margin-right: auto;
	height: 40px;
	border: 1px solid #384457;
	border-radius: 25px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 20px;
}

.searchField {
	width: 100%;
	font-size: 20px;
	border: none;
	outline: none;
	background-color: transparent;
}

.searchIcon {
	width: 26px;
	height: 24px;
}

.noSearchContainer {
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	font-weight: 600;
}

.noSearchContainer p {
	font-size: 20px;
	color: $primary-color;
}

.noSearch {
	width: 211px;
	height: 150px;
}

.filterButton {
	display: none;
}

@media only screen and (max-width: 620px) {
	.searchFilterContainer {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100vh;
		width: 100vw;
		display: none;
		background-color: white;
		font-size: 30px;
		z-index: 1000;
		padding-left: 20px;
		padding-right: 20px;
		overflow-y: scroll;
		padding-bottom: 200px;
	}

	.searchFieldContainer {
		width: 100%;
		margin-bottom: 20px;
		margin-top: 20px;
	}

	.filterButton {
		display: block;
		width: 160px;
		font-size: 16px;
		margin-top: 20px;
	}

	.searchFilterContainerActive {
		display: block;
	}

	.brandCard:hover {
		background-color: transparent;
	}

	.closeIconContainer {
		position: absolute;
		right: 0px;
		top: 0px;
		padding: 40px;
		.closeIcon {
		  display: inline-block;
		  height: 40px;
		  width: 40px;
		  cursor: pointer;
		  stroke: $primary-color;
			.circle {
			  opacity: 0.1;
			}		  
		}
	}
}