.app {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.appRouter {
	height: 100%;
	flex-grow: 1;
	display: flex;
}