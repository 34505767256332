@import '../../shared.scss';

.requestViewContainer {
	min-height: 100%;
	background-color: whitesmoke;
	width: 100%;
	padding: 20px;
}

.requestContainer {
	margin-left: auto;
	margin-right: auto;
	display: block;
	width: 652px;
}

.requestContainer {
	max-width: 100%;
}

.requestForm {
	width: 100%;
}

.helperText {
	@extend %helperText;
	margin-top: 5px;
}