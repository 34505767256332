@import '../../shared.scss';

.tikTokContainer {
	min-height: 100%;
	background-color: whitesmoke;
	width: 100%;
	padding: 20px;
}

.content {
	@extend %content;
}