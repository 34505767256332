* {
	box-sizing: border-box;
	font: optional;
}

html {
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Raleway',	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	height: 100%;
}

#root {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}
